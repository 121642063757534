import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { AuthGuardService } from 'carehub-shared/services/auth-guard.service';
import { AboutComponent } from './core/components/about/about.component';
import { CallContextSwapComponent } from './core/components/call-validation/call-context-swap/call-context-swap.component';
import { CallValidationComponent } from './core/components/call-validation/call-validation/call-validation.component';
import { ErrorComponent } from './core/components/error/error.component';
import { HealthComponent } from './core/components/health/health.component';
import { HelpComponent } from './core/components/help/help.component';
import { HomeComponent } from './core/components/home/home.component';
import { NotFoundComponent } from './core/components/not-found/not-found.component';
import { LoginRedirectComponent } from './core/components/redirects/login-redirect.component';
import { LogoutRedirectComponent } from './core/components/redirects/logout-redirect.component';
import { SilentRedirectComponent } from './core/components/redirects/silent-redirect.component';
import { TestComponent } from './core/components/test/test.component';
import { UnauthorizedComponent } from './core/components/unauthorized/unauthorized.component';

interface ExtendedRoute extends Route {
  businessModuleId?: number;
}

export declare type Routes = ExtendedRoute[];

export const MEMBER_SERVICES_ROOT = 'member-services-mgmt';

const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent,
  },
  {
    path: 'call-validation',
    component: CallValidationComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'call-context-swap',
    component: CallContextSwapComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'test',
    component: TestComponent,
  },
  {
    path: 'error',
    component: ErrorComponent,
  },
  {
    path: 'member-services-mgmt',
    loadChildren: () =>
      import(
        './business-modules/member-services-mgmt/member-services-mgmt.module'
      ).then((m) => m.MemberServicesManagementModule),
    businessModuleId: 1,
    canActivate: [AuthGuardService],
  },
  {
    path: 'finance',
    loadChildren: () =>
      import('./business-modules/finance-mgmt/finance-mgmt.module').then(
        (m) => m.FinanceManagementModule
      ),
    businessModuleId: 2,
    canActivate: [AuthGuardService],
  },
  {
    path: 'priorauth',
    loadChildren: () =>
      import('./business-modules/priorauth/priorauth.module').then(
        (m) => m.PriorAuthModule
      ),
    businessModuleId: 0xbeef,
    canActivate: [AuthGuardService],
  },
  {
    path: 'security',
    loadChildren: () =>
      import('./business-modules/security-mgmt/security-mgmt.module').then(
        (m) => m.SecurityManagementModule
      ),
    businessModuleId: 3,
    canActivate: [AuthGuardService],
  },
  {
    path: 'client-acct-mgmt',
    loadChildren: () =>
      import(
        './business-modules/client-acct-mgmt/client-acct-mgmt.module'
      ).then((m) => m.ClientAcctMgmtModule),
    businessModuleId: 5,
    canActivate: [AuthGuardService],
  },
  {
    path: 'provider-network-mgmt',
    loadChildren: () =>
      import(
        './business-modules/provider-network-mgmt/provider-network-mgmt.module'
      ).then((m) => m.ProviderNetworkMgmtModule),
    businessModuleId: 4,
    canActivate: [AuthGuardService],
  },
  {
    path: 'login-redirect',
    component: LoginRedirectComponent,
  },
  {
    path: 'logout-redirect',
    component: LogoutRedirectComponent,
  },
  {
    path: 'silent-redirect',
    component: SilentRedirectComponent,
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent,
  },
  {
    path: 'about',
    component: AboutComponent,
  },
  {
    path: 'health',
    component: HealthComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'help',
    component: HelpComponent,
  },
  {
    path: 'not-found',
    component: NotFoundComponent,
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: 'not-found',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
