const DEV_GATEWAY = 'https://gw.dev.edh-nonprod.com';

export const BASE_ENVIRONMENT = {
  production: false,
  logoEnvText: 'LOCAL',
  environmentName: 'Local',
  gateway: DEV_GATEWAY,

  blobStorage: 'https://comedhccarehubdev.blob.core.windows.net',
  claimsFaxLine: '8889654011',
  receiptFax: '8889654011',
  receiptEmail: 'accounting@lanterncare.com',
  preLaunchTheme: 'Dark,Light',
  launchStatus: 'PreLaunch,Launched',
  // deepcode ignore HardcodedNonCryptoSecret: just a key, not a secret
  googleMapsApiKey: 'AIzaSyAsoRR9YHAbG75tvj0xT1uWGjJA9TMSsjc',
  surveyJsKey:
    'M2U3N2RhZGEtODI2NC00YTdjLTgwNzktY2ZhY2E3MTY0NDZhOzE9MjAyNS0xMS0yMSwyPTIwMjUtMTEtMjEsND0yMDI1LTExLTIx',
};

export const OIDC_DEFAULTS = {
  authority: DEV_GATEWAY + '/sts',
  redirect_uri: origin + '/login-redirect',
  post_logout_redirect_uri: origin + '/logout-redirect',
  client_id: 'carehub-web',
  scope: 'openid profile carehub',
  response_type: 'id_token token',
  silent_redirect_uri: '/',
};

export const APP_INSIGHTS_DEFAULTS = {
  enableCorsCorrelation: true,
  autoTrackPageVisitTime: true,
  verboseLogging: true,
  disableDataLossAnalysis: false,
  disableCorrelationHeaders: false,
  correlationHeaderExcludedDomains: Array<string>(),
};
