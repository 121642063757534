<div [class.ch-optional-host-container]="true">
  <div class="action-control-holder">
    <span class="grid-title">{{ gridTitle }}</span>
    <ng-container *enIfWriteAllowed="permissionName; else forbiddenIcon">
      <!--put tooltip in containing div so it works when the button is disabled-->
      <div *ngIf="!disableAdd" class="button-container" matTooltip="Add New">
        <button
          class="add"
          mat-icon-button
          (click)="onAdd($event)"
          [routerLink]="determineLink()"
        >
          <mat-icon aria-label="Add item">add_box</mat-icon>
        </button>
      </div>
    </ng-container>
    <ng-template #forbiddenIcon>
      <mat-icon
        *ngIf="disableAdd"
        class="forbidden-icon"
        matTooltip="You do not have permission to edit this section."
        >lock
      </mat-icon>
    </ng-template>
  </div>

  <div class="mat-elevation-z8 tableBodyContent">
    <mat-progress-bar class="wait-notifier" [mode]="progressMode" value="100">
    </mat-progress-bar>

    <div class="filtering-controls">
      <ng-content select="[mainFilters]"></ng-content>
    </div>

    <ng-container *ngIf="selectableType === 'Checkbox'" matColumnDef="__select">
      <mat-checkbox
        class="select-checkbox"
        *ngIf="allowSelectAll"
        (change)="onMasterToggle($event)"
        disableRipple="true"
        [checked]="selection.hasValue() && isAllSelected()"
        >Select All
      </mat-checkbox>
    </ng-container>

    <mat-accordion
      #gridAccordion
      displayMode="flat"
      [multi]="allowOpenMultiple"
      class="mat-table"
      [class.is-busy]="dataSourceObject?.isLoading"
      *ngFor="let row of dataSourceObject?.results; index as i"
    >
      <ng-container *ngIf="i === 0 || repeatHeader">
        <section
          *ngIf="columns"
          matSort
          [matSortDirection]="smartListCriteria?.sortDirection"
          [matSortActive]="smartListCriteria?.sortField"
          (matSortChange)="onSort($event)"
          class="mat-elevation-z2 mat-header-row"
          [ngStyle]="headerStyle?.cssStyleResolver(row)"
        >
          <ng-container *ngFor="let column of columns">
            <span
              [mat-sort-header]="column.columnDef"
              [class]="
                repeatHeader ? 'mat-repeat-header-cell' : 'mat-header-cell'
              "
              [disabled]="!column.sortable"
              [class.show-sortable]="column.sortable"
              [attr.mat-sort-header]="column.columnDef"
              [ngStyle]="
                useCustomWidths
                  ? { 'flex-basis': column.width, 'flex-grow': '0' }
                  : {}
              "
            >
              {{ column.header }}
            </span>
          </ng-container>
        </section>
      </ng-container>
      <mat-expansion-panel
        [hideToggle]="hideToggle"
        [expanded]="(i === 0 && expandFirstRow) || expandAllRows"
      >
        <mat-expansion-panel-header
          class="mat-row"
          (click)="itemRowClicked(row)"
          [ngStyle]="rowDetails?.cssStyleResolver(row)"
          [matTooltip]="rowToolTipDetails?.toolTipResolver(row)"
          matTooltipClass="multi-line-tooltip"
        >
          <span
            *ngFor="let column of columns; let i = index"
            class="mat-cell"
            [ngStyle]="
              useCustomWidths
                ? { 'flex-basis': column.width, 'flex-grow': '0' }
                : {}
            "
            [class.align-content]="column.alignText != null"
            [class.align-left]="column.alignText === ColumnTextAlign.Left"
            [class.align-right]="column.alignText === ColumnTextAlign.Right"
            [class.align-center]="column.alignText === ColumnTextAlign.Center"
            ><ng-container *ngVar="column.cell(row, i, rowCount) as val">
              <ng-container [ngSwitch]="typeOf(column, val)">
                <ng-container *ngSwitchCase="'array'">
                  <div *ngFor="let subRow of val">{{ subRow }}</div>
                </ng-container>
                <div *ngSwitchCase="'icons'" class="icons">
                  <ng-container
                    *ngFor="
                      let iconEntry of getCellValueIcons(val);
                      let iconIndex = index
                    "
                  >
                    <ng-container
                      *ngIf="
                        getIcon(iconIndex, column, iconEntry) as iconDetails
                      "
                    >
                      <mat-icon
                        *ngIf="
                          iconDetails &&
                          (!iconDetails.render || iconDetails.render(row))
                        "
                        [style.color]="iconDetails.color"
                        (click)="onIconClick($event, row, iconDetails)"
                        [matTooltip]="
                          iconDetails.toolTipResolver
                            ? iconDetails.toolTipResolver(row)
                            : iconDetails.toolTip
                        "
                      >
                        {{ iconDetails.icon }}</mat-icon
                      >
                    </ng-container>
                  </ng-container>
                </div>
                <ng-container *ngSwitchCase="'observable'">
                  <ng-container
                    *ngIf="column.type !== 'currency'; else currency"
                  >
                    {{ val | async }}
                  </ng-container>
                  <ng-template #currency>
                    {{
                      val | async | currency : 'USD' : 'symbol-narrow' : '1.2-2'
                    }}
                  </ng-template>
                </ng-container>

                <ng-container
                  *ngSwitchCase="'control'"
                  [ngSwitch]="column.controlDef.type"
                >
                  <ng-container *ngSwitchCase="'checkbox'">
                    <mat-checkbox
                      (change)="onCheckBoxClicked($event, row, column)"
                      disableRipple="true"
                      [disabled]="
                        column.controlDef.isDisabled &&
                        column.controlDef.isDisabled(row)
                      "
                      [checked]="
                        selectAllCheckboxes
                          ? true
                          : column.controlDef.bindingDataSource(row)
                      "
                      (click)="$event.stopPropagation()"
                    >
                    </mat-checkbox>
                  </ng-container>
                </ng-container>

                <ng-container *ngSwitchDefault>
                  <ng-container
                    *ngIf="column.type !== 'currency'; else currency"
                  >
                    <ng-container
                      *ngIf="
                        column.cellValueMaxLength &&
                          val &&
                          val.length > column.cellValueMaxLength;
                        else unTruncated
                      "
                    >
                      <span
                        [matTooltip]="val"
                        matTooltipClass="cell-value-tip"
                        >{{
                          val.slice(0, column.cellValueMaxLength) + '…'
                        }}</span
                      >
                    </ng-container>
                  </ng-container>
                  <ng-template #unTruncated>
                    {{ val }}
                  </ng-template>
                  <ng-template #currency>
                    {{
                      val | currency : 'USD' : 'symbol-narrow' : '1.2-2'
                    }}</ng-template
                  >
                </ng-container>
              </ng-container>
            </ng-container></span
          >
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <ng-container
            *ngTemplateOutlet="
              rowTemplate || itemTemplate || defaultTemplate;
              context: { rowValue: row, rowIndex: i }
            "
          >
          </ng-container
        ></ng-template>
      </mat-expansion-panel>
    </mat-accordion>
    <mat-paginator
      #paginator
      *ngIf="showPaginator"
      [length]="rowCount"
      [pageIndex]="smartListCriteria?.pageIndex"
      [pageSize]="smartListCriteria?.pageSize"
      [pageSizeOptions]="[10, 25, 50]"
      (page)="onPage($event)"
      [class.infinite]="infiniteScroll"
    ></mat-paginator>
    <div
      class="no-items-footer"
      *ngIf="dataSourceObject?.results?.length === 0 && isReadingAllowed"
    >
      No Results Found.
    </div>
    <div class="not-allowed-footer" *ngIf="!isReadingAllowed">
      You do not have permission to this section.
    </div>
  </div>
</div>

<ng-template #defaultTemplate let-row let-i
  ><div *ngIf="showTemplateHelp">
    No template specified, expecting template definition for 'itemTemplate' the
    template gets the variables 'templateRow' and 'templateIdx' mapping
    respectively to the row data passed to the template, and the index of that
    row.
    <br />
    <pre><code>
&lt;!-- EXAMPLE EXPANDED ROW TEMPLATE: Claims Details Component --&gt;
&lt;ng-template *enIfReadAllowed=&quot;'ChargeSegmentRead'&quot;
  #rowTemplate                            // &lt;-- declare template name (required, must be 'rowTemplate')
  let-rowVal=&quot;rowValue&quot;                 // &lt;-- declare variable for the template context (required)
  let-rowIdx=&quot;rowIndex&quot; &gt;
  &lt;ch-claim-details                        // &lt;-- define component for expanded rows
    [claim]=&quot;rowVal&quot;                       // &lt;-- use the variables defined above
    [claimIndex]=&quot;rowIdx&quot;
    (saved)=&quot;onClaimSaved($event, rowVal)&quot;
  &gt;&lt;/ch-claim-details&gt;
&lt;/ng-template&gt;
  </code></pre>
  </div></ng-template
>
