export enum LookupTypes {
  AnesBillingTypes = 'AnesBillingTypes',
  AnesthesiologistContactTypes = 'AnesthesiologistContactTypes',
  BillingFrequencyTypes = 'BillingFrequencyTypes',
  BillTransmissionTypes = 'BillTransmissionTypes',
  ClientContactTypes = 'ClientContactTypes',
  ClinicContactTypes = 'ClinicContactTypes',
  InvoicingModels = 'InvoicingModels',
  FacilityContactTypes = 'FacilityContactTypes',
  MemberContactTypes = 'MemberContactTypes',
  MgmtCompanyContactTypes = 'MgmtCompanyContactTypes',
  ProviderContactTypes = 'ProviderContactTypes',
  ProviderGroupContactTypes = 'ProviderGroupContactTypes',
  ProviderRecStatuses = 'ProviderRecStatuses',
  ActivityDirections = 'ActivityDirections',
  ActivityReasons = 'ActivityReasons',
  ActivityRelatedTo = 'ActivityRelatedTo',
  ActivityTypes = 'ActivityTypes',
  ActivityWiths = 'ActivityWiths',
  AddressTypes = 'AddressTypes',
  CareCentralStatuses = 'CareCentralStatuses',
  CaseEventStatuses = 'CaseEventStatuses',
  CaseStatuses = 'CaseStatuses',
  ClaimSources = 'ClaimSources',
  ClaimStatuses = 'ClaimStatuses',
  ClaimTypes = 'ClaimTypes',
  ClaimFormTypes = 'ClaimFormTypes',
  ClaimDeferralDispositions = 'ClaimDeferralDispositions',
  ClaimRejectionDispositions = 'ClaimRejectionDispositions',
  ClaimReversalDispositions = 'ClaimReversalDispositions',
  ClaimReviewStatuses = 'ClaimReviewStatuses',
  ReimbursementStatuses = 'ReimbursementStatuses',
  ClientStatuses = 'ClientStatuses',
  ClosedReasons = 'ClosedReasons',
  CollectionLimitTypes = 'CollectionLimitTypes',
  CollectionTimings = 'CollectionTimings',
  ConsultTypes = 'ConsultTypes',
  ContactMethods = 'ContactMethods',
  ContactTypes = 'ContactTypes',
  ContractTypes = 'ContractTypes',
  ContractContactTypes = 'ContractContactTypes',
  ContractStatuses = 'ContractStatuses',
  CoverageTypes = 'CoverageTypes',
  DeductibleTypes = 'DeductibleTypes',
  DiagnoserTypes = 'DiagnoserTypes',
  Dispositions = 'Dispositions',
  DisputeReasons = 'DisputeReasons',
  DisputeStatuses = 'DisputeStatuses',
  DisputeWiths = 'DisputeWiths',
  DocumentTypes = 'DocumentTypes',
  EligibilityTypes = 'EligibilityTypes',
  EscalationTypes = 'EscalationTypes',
  EligibleProcedureItemCategories = 'EligibleProcedureItemCategories',
  ExemptionConfigurationTypes = 'ExemptionConfigurationTypes',
  FacilityStatuses = 'FacilityStatuses',
  HolderTypes = 'HolderTypes',
  IncentivePayorTypes = 'IncentivePayorTypes',
  InquiryStatuses = 'InquiryStatuses',
  InquiryTypes = 'InquiryTypes',
  Languages = 'Languages',
  ManagementCompanyStatuses = 'ManagementCompanyStatuses',
  MedicalRecordNewProviderStatuses = 'MedicalRecordNewProviderStatuses',
  MedicalRecordReleaseStatuses = 'MedicalRecordReleaseStatuses',
  MedicalRecordReleaseRecipientTypes = 'MedicalRecordReleaseRecipientTypes',
  MedicalRecordTransferStatuses = 'MedicalRecordTransferStatuses',
  MemberInvoiceStatuses = 'MemberInvoiceStatuses',
  MemberJourneyStatuses = 'MemberJourneyStatuses',
  MemberStatuses = 'MemberStatuses',
  MessageSourceTypes = 'MessageSourceTypes',
  NewPatientReferralStatuses = 'NewPatientReferralStatuses',
  NoteTypes = 'NoteTypes',
  OOPMTypes = 'OOPMTypes',
  ParticipantTypes = 'ParticipantTypes',
  PartyTypes = 'PartyTypes',
  PaymentMethods = 'PaymentMethods',
  PaymentPreferenceTypes = 'PaymentPreferenceTypes',
  PermissionScopes = 'PermissionScopes',
  PlanIncentivePaymentChannels = 'PlanIncentivePaymentChannels',
  PlanReviewStatus = 'PlanReviewStatus',
  PlanTypes = 'PlanTypes',
  PricingMethods = 'PricingMethods',
  Priorities = 'Priorities',
  ProcedureItems = 'ProcedureItems',
  ProcedureItemCategories = 'ProcedureItemCategories',
  Products = 'Products',
  ProviderStatuses = 'ProviderStatuses',
  ProviderGroupStatuses = 'ProviderGroupStatuses',
  QuestionTypes = 'QuestionTypes',
  QuestionnaireTypes = 'QuestionnaireTypes',
  QuestionnaireQuestionTypes = 'QuestionnaireQuestionTypes',
  RawClaimStatuses = 'RawClaimStatuses',
  ReferralSourceCategories = 'ReferralSourceCategories',
  ReferralSources = 'ReferralSources',
  ReimbursementPricingStatuses = 'ReimbursementPricingStatuses',
  ReimbursementDisputePricingStatuses = 'ReimbursementDisputePricingStatuses',
  ReimbursementPaymentStatuses = 'ReimbursementPaymentStatuses',
  ReimbursementDisputePaymentStatuses = 'ReimbursementDisputePaymentStatuses',
  ReimbursementInvoiceStatuses = 'ReimbursementInvoiceStatuses',
  RelationshipTypes = 'RelationshipTypes',
  ResolutionChannels = 'ResolutionChannels',
  RewardPaymentStatuses = 'RewardPaymentStatuses',
  RewardPayoutTimingTypes = 'RewardPayoutTimingTypes',
  SegmentPricingStatuses = 'SegmentPricingStatuses',
  SegmentDisputePricingStatuses = 'SegmentDisputePricingStatuses',
  SegmentPaymentStatuses = 'SegmentPaymentStatuses',
  ServiceLineTypes = 'ServiceLineTypes',
  ServiceTypes = 'ServiceTypes',
  Sexes = 'Sexes',
  SpecialtyTypes = 'SpecialtyTypes',
  States = 'States',
  SurveyStatuses = 'SurveyStatuses',
  SurgeryPlusOptions = 'SurgeryPlusOptions',
  SurgicalStatuses = 'SurgicalStatuses',
  TaskPriorities = 'TaskPriorities',
  TaskTypes = 'TaskTypes',
  TaxStatuses = 'TaxStatuses',
  TaxIdTypes = 'TaxIdTypes',
  TeamTypes = 'TeamTypes',
  AssignmentStatuses = 'AssignmentStatuses',
  UMAdministratorTypes = 'UMAdministratorTypes',
  UMSubmitterTypes = 'UMSubmitterTypes',
  VendorTypes = 'VendorTypes',
  VendorRelationshipTypes = 'VendorRelationshipTypes',
  VpayPaymentStatuses = 'VpayPaymentStatuses',
  WorkTypes = 'WorkTypes',
  ClientTypes = 'ClientTypes',
  AgeGroup = 'AgeGroup',
  AccumulatorCoverages = 'AccumulatorCoverages',
  AccumulatorTypes = 'AccumulatorTypes',
  BillingTransmissionPreferenceTypes = 'BillingTransmissionPreferenceTypes',
  ClientBillStatuses = 'ClientBillStatuses',
  SurgeryStatuses = 'SurgeryStatuses',
  PricingAuditActionTypes = 'PricingAuditActionTypes',
  ClientPriority = 'ClientPriority',
  PrePaymentAuditStatuses = 'PrePaymentAuditStatuses',
  AuditRejectReasons = 'AuditRejectReasons',
}
